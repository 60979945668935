import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Workspace } from '@lims-common-ux/lux';
import { AppStateService } from 'src/app/app-state.service';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnDestroy {
  constructor(private title: Title, route: ActivatedRoute, private appState: AppStateService) {
    route.data.subscribe((workspace: Workspace) => {
      if (workspace == null) {
        // need to deal with workspaces from other labs somehow.
        this.title.setTitle(this.appState.defaultPageTitle);
      } else {
        let appTitle = workspace.name;

        if (!appTitle) {
          appTitle = this.appState.defaultPageTitle;
        } else if (this.appState.env === 'uat' || this.appState.env === 'exp') {
          appTitle += ` (${this.appState.env})`;
        }

        this.title.setTitle(appTitle);
      }
    });
  }

  ngOnDestroy() {
    this.title.setTitle(this.appState.defaultPageTitle);
  }
}
