<div luxLayout="row" luxLayoutAlign="start start" style="position: relative">
    <div luxLayout="column" class="card-badges">
        <div class="card-badge card-badge-selected" *ngIf="isSelected">
      <span>
        <svg class="spot-icon assay-selected-icon" aria-labelledby="title">
          <title>{{ 'LABELS.SELECTED' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#show"></use>
        </svg>
      </span>
        </div>
        <div
                class="card-badge card-badge-released"
                *ngIf="assay.status === 'RELEASED'"
                [ngClass]="isSelected ? 'card-badge-release-selected' : ''">
            <!-- released assays render a start on the top left corner when not selected -->
            <span>
        <svg
                [attr.data-cy]="assay.testCode + '-released-badge'"
                class="spot-icon assay-released-icon"
                aria-labelledby="title">
          <title>{{ 'LABELS.RELEASED' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#favorite-featured"></use>
        </svg>
      </span>
        </div>

        <div
                *ngIf="this.assay.status === 'CANCELED'"
                class="card-badge card-badge-canceled">
      <span>
        <svg class="spot-icon assay-canceled-icon" data-cy="assay-canceled-icon" aria-labelledby="title">
          <title>{{ 'LABELS.CANCELED' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#cancel"></use>
        </svg>
      </span>
        </div>

        <div
                *ngIf="this.assay.status === 'TECHNICIAN_REVIEW' || assay.currentResult?.technicallyInvalid"
                class="card-badge card-badge-technician-review">
      <span>
        <svg class="spot-icon assay-alert-icon" data-cy="assay-alert-icon" aria-labelledby="title">
          <title>{{ 'LABELS.TECHNICIAN_REVIEW' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#alert-notification"></use>
        </svg>
      </span>
        </div>

        <div class="card-badge card-badge-has-transformed-value" *ngIf="assay?.currentResult?.transformedValue">
      <span>
        <svg
                [attr.data-cy]="assay.testCode + '-has-transformed-value-badge'"
                aria-labelledby="title"
                class="spot-icon assay-has-transformed-value-icon"
                data-cy="assay-has-transformed-value-icon">
          <title>{{ assay?.currentResult?.transformedValue }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#info"></use>
        </svg>
      </span>
        </div>
    </div>
    <div
            class="card-wrapper"
            data-cy="card-wrapper"
            *ngIf="assay"
            [attr.data-cy-assay]="assay.testCode"
            [ngClass]="presentationClass">
        <div
                luxLayout="column"
                class="card"
                [ngClass]="[!isSelected && assay.status === 'RELEASED' ? 'released' : '']"
                data-cy="card"
                (dblclick)="handleDoubleClick($event)"
                (click)="handleClick($event)"
                (mouseenter)="toggleIsHovered()"
                (mouseleave)="toggleIsHovered()"
                (keydown.escape)="handleEscapeKeydown($event)"
                (keydown.enter)="handleEnterKeydown($event)"
                (keydown)="handleKeydown($event)">
            <div class="card-header" luxLayout="row">
                <div
                        class="card-header-left result-name spot-typography__heading--level-6"
                        luxFlex="85%"
                        data-cy="assay-testcode"
                        luxLayoutAlign="start">
                    {{ assay.testCode }}
                </div>
                <div luxLayoutAlign="end end" class="card-header-right" luxLayout="row">
                    <svg
                            *ngIf="assay?.comments && assay?.comments?.length"
                            class="spot-icon assay-comment-icon"
                            data-cy="assay-comment-icon"
                            aria-labelledby="title">
                        <title>{{ 'LABELS.HAS_COMMENTS' | translate }}</title>
                        <use xlink:href="./assets/icons/spot_icons.svg#communicate"></use>
                    </svg>
                </div>
            </div>
            <div #cardBody class="card-body" luxLayoutAlign="center center">
                <a
                        href="javaScript:void(0);"
                        class="card-value"
                        *ngIf="!editable || preventResultInput"
                        #displayValueLink
                        [class.has-transformed-value]="assay?.currentResult?.transformedValue"
                        (focus)="highlightCard()"
                        (keydown.space)="handleSpace()"
                        data-cy="assay-value">
          <span *ngIf="assay.currentResult?.value && assaysService.getAssayStatusPresentation(assay) !== 'no-result'">{{
              assay.currentResult?.value
              }}</span>
                    <span *ngIf="!assay.currentResult?.value || assaysService.getAssayStatusPresentation(assay) === 'no-result'"
                    >&nbsp;&nbsp;</span
                    >
                </a>
                <app-result-input
                        [hidden]="!editable"
                        (inputBlurred)="blurRunResultInput()"
                        (assayUpdated)="assayUpdated.emit($event)"
                        (space)="handleSpace()"
                        #runValue
                        *ngIf="!preventResultInput"
                        [assay]="assay"></app-result-input>
            </div>
            <div
                    *ngIf="
          (assay.status === 'OPENED' || assay.status === 'CANCELED') &&
          lab.id !== assay.expectedPerformingLab &&
          (!assay?.currentResult || assay?.currentResult.value === null)
        "
                    class="card-body-background"
                    luxLayout="column"
                    luxLayoutAlign="center">
                <span data-cy="expected-performing-lab">{{ assay.expectedPerformingLab }}</span>
            </div>

            <div
                    *ngIf="
          assay.clinicalReference && assay.status !== 'OPENED' && assay.currentResult && !assay.currentResult?.noResult
        "
                    class="assay-card-clinical-reference-wrapper"
                    luxLayout="row">
                <lux-clinical-reference
                        luxFill
                        [indicator]="assay.currentResult?.relevanceIndicator"
                        [clinicalReference]="assay.clinicalReference"></lux-clinical-reference>
            </div>

            <div class="card-footer" luxLayout="row">
                <div class="assay-card-instrument-flags-wrapper" luxLayoutAlign="center center" luxLayout="row">
                    <div class="assay-card-instrument-flags" luxLayoutAlign="center center" luxLayout="row"
                         luxLayoutGap="3px">
                        <ng-container *ngFor="let flag of assay.currentResult?.flags">
              <span
                      data-cy="assay-card-instrument-flag"
                      title="{{ flag.message }}"
                      class="assay-card-instrument-flag characters"
              >{{ flag.code }}</span
              >
                        </ng-container>
                    </div>
                </div>

                <span
                        (click)="batchAssay()"
                        [hidden]="(!isBatched && !isSelected && !isHovered) || assay.status === 'CANCELED'"
                        [class.batched]="isBatched"
                        title="{{ 'LABELS.SELECTED' | translate }}"
                        data-cy="assay-batch-icon"
                        class="batch-icon">
          <span></span>
        </span>
            </div>
        </div>
    </div>
</div>
