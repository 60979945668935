import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PageResource } from './interfaces/pageResource.interface';
import { forkJoin, throwError, catchError, filter, map, switchMap, tap, firstValueFrom, of } from 'rxjs';
import { FeaturesService, LabsService, UserService, EnvironmentService, LoggerService } from '@lims-common-ux/lux';
import { StaticAppData } from './interfaces/application-data.interface';
import { ClientAuthError, ClientAuthErrorMessage, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService } from '@azure/msal-angular';
import { appSettings } from '../environments/app-settings';
import { environment } from '../environments/environment';

const pageResourcePath = appSettings.pageResource;

export const PAGE_RESOURCE = new InjectionToken<PageResource>('pageResource', {
  factory(): PageResource {
    const service = inject(ApplicationInitService);
    return service.staticAppData.pageResource;
  },
  providedIn: 'root',
});

@Injectable({
  providedIn: 'root',
})
export class ApplicationInitService {
  private _staticAppData = {} as StaticAppData;

  get appEnvironment(): { production: boolean } {
    return environment;
  }

  constructor(
    private http: HttpClient,
    private labsService: LabsService,
    private envService: EnvironmentService,
    private userService: UserService,
    private featuresService: FeaturesService,
    @Inject('Document') private document: Document,
    private broadcaster: MsalBroadcastService,
    private loggerService: LoggerService
  ) {}
  /**
   * Promise used here because it's required by Angular's initialization code.
   */
  initialize(): Promise<StaticAppData> {
    this._staticAppData = {} as StaticAppData;

    return firstValueFrom(
      this.broadcaster.inProgress$.pipe(
        filter((progress) => progress === InteractionStatus.None),
        switchMap(() => {
          return this.http.get<PageResource>(pageResourcePath).pipe(
            catchError((err: HttpErrorResponse | ClientAuthError) => {
              // ignoring auth errors as those are "normal" and the user will get redirected to Azure for login
              if (!this.isUserAuthRequiredError(err)) {
                this.document.getElementById('app-loader').style.display = 'none';
                this.document.getElementById('initialization-error').style.display = '';
              }
              return throwError(() => err);
            }),
            switchMap((pageResource: PageResource) => {
              this._staticAppData.pageResource = pageResource;

              return forkJoin([
                this.labsService.getLabs(pageResource._links.labs),
                this.userService.getUser(pageResource._links.user),
                this.featuresService.getFeatures(pageResource._links.features),
                this.envService.getEnvironment(pageResource._links.info),
                this.appEnvironment.production ? this.loggerService.init() : of({}),
              ]);
            }),
            tap((forkResp: any[]) => {
              this._staticAppData.labs = forkResp[0];
              this._staticAppData.currentUser = forkResp[1];
              this._staticAppData.environment = forkResp[3]?.environment;
            }),
            map(() => this._staticAppData)
          );
        })
      )
    );
  }

  /**
   * Ideally this would just take HttpErrorResponse, but it doesn't look like the actual code throws that, it somehow,
   * just gets a raw ClientAuthError. Not sure where HttpErrorResponse is getting unwrapped, but we never see it
   * @param error
   * @private
   */
  private isUserAuthRequiredError(error: HttpErrorResponse | ClientAuthError): boolean {
    const authErrorCode = ClientAuthErrorMessage.tokenRefreshRequired.code;
    if (error instanceof HttpErrorResponse && error.status === 401) {
      return true;
    } else if (error instanceof ClientAuthError && error.errorCode === authErrorCode) {
      return true;
    } else {
      return false;
    }
  }

  get staticAppData(): StaticAppData {
    return this._staticAppData;
  }
}
