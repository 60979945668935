<div luxFill luxLayout="row">
    <div id="assay-details" luxLayout="column" #cmpWrapper (keydown.escape)="handleEscape($event)">
        <!-- WORKSPACE NAVIGATION -->
        <app-workspace-navigation
                (acceptAll)="triggerAcceptAll()"
                [showAcceptAll]="true"
                [disableAcceptAll]="disableAcceptAll"
                [workspaceAccession]="accession"
                [headerAccession]="headerAccession"
                [currentWorkspace]="workspace"></app-workspace-navigation>
        <!-- WORKSPACE NAVIGATION -->

        <!-- ASSAY DETAIL -->
        <div
                *ngIf="selectedAssay && !batchedAssays.length"
                luxFlex
                id="selected-assay-wrapper"
                [ngClass]="assaysService.getAssayStatusPresentation(selectedAssay)"
                luxLayout="column">
            <!-- ASSAY HEADER -->
            <div id="selected-assay-top" luxLayout="row">
                <div luxLayout="column" luxFlex>
                    <div luxLayout="row" luxLayoutAlign="space-between center">
                        <div class="assay-code"
                             [ngClass]="[selectedAssay.status === 'TECHNICIAN_REVIEW' ? 'ALERT' : '']">
              <span data-cy="no-result-indicator" class="no-result-indicator">
                <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                  <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
                  <use xlink:href="./assets/icons/spot_icons.svg#blocked"></use>
                </svg>
              </span>

                            <span data-cy="canceled-indicator" class="canceled-indicator">
                <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                  <title>{{ 'LABELS.CANCELED' | translate }}</title>
                  <use xlink:href="./assets/icons/spot_icons.svg#cancel"></use>
                </svg>
              </span>

                            {{ selectedAssay?.testCode }}
                        </div>

                        <!-- ASSAY ACTION BUTTONS -->
                        <div
                                luxLayout="row"
                                id="assay-action-buttons"
                                data-cy="assay-action-buttons"
                                luxLayoutAlign="end center"
                                luxLayoutGap="20px">
                            <button
                                #validateAssay
                                [disabled]="!selectedAssay.currentResult?._links?.acceptResult"
                                id="validateAssay"
                                data-cy="accept-btn"
                                (click)="acceptResult(selectedAssay.currentResult)"
                                title="{{ 'LABELS.ACCEPT' | translate }}"
                                class="spot-button spot-button--primary spot-button--icon-only spot-button--with-icon">
                                <svg class="spot-icon spot-button__icon spot-button__icon--left"
                                     aria-labelledby="title">
                                    <title>{{ 'LABELS.ACCEPT' | translate }}</title>
                                    <use xlink:href="./assets/icons/spot_icons.svg#checkmark"></use>
                                </svg>
                            </button>

                            <button
                                    id="repeat"
                                    data-cy="repeat-button"
                                    [disabled]="!selectedAssay._links?.requestRepeat"
                                    (click)="repeat()"
                                    title="{{ 'LABELS.REPEAT' | translate }}"
                                    class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
                                <svg class="spot-icon spot-button__icon spot-button__icon--left"
                                     aria-labelledby="title">
                                    <title>{{ 'LABELS.REPEAT' | translate }}</title>
                                    <use xlink:href="./assets/icons/spot_icons.svg#refresh-redo"></use>
                                </svg>
                            </button>

                            <button
                                    id="no-result-btn"
                                    data-cy="no-result-button"
                                    [disabled]="!selectedAssay._links?.markAsNoResult"
                                    (click)="noResult()"
                                    title="{{ 'LABELS.NO_RESULT' | translate }}"
                                    class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
                                <svg class="spot-icon spot-button__icon spot-button__icon--left"
                                     aria-labelledby="title">
                                    <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
                                    <use xlink:href="./assets/icons/spot_icons.svg#blocked"></use>
                                </svg>
                            </button>
                        </div>
                        <!-- / ASSAY ACTION BUTTONS -->
                    </div>

                    <!-- COMMENTS -->
                    <lux-comments
                            [allowAdd]="!!selectedAssay._links?.addComment"
                            [lab]="lab"
                            [commentsDataSource]="commentsDataSource"
                            [context]="selectedAssay"
                            [placeholder]="'LABELS.COMMENTS_SEARCH' | translate"
                            [expandable]="true"
                            (addComment)="onAddComment($event)"
                            (removeComment)="onRemoveComment($event)"
                            data-cy="app-comments"
                            #resultComment></lux-comments>
                    <!-- /COMMENTS -->
                </div>
            </div>
            <!-- /ASSAY HEADER -->
            <div id="selected-assay-bottom" luxFlex luxFill>
                <!-- PREVIOUS RESULTS -->
                <div *ngFor="let result of selectedAssay.results" class="run-card previous-result">
                    <div
                            class="run-card-inner"
                            [ngClass]="[
              result.acceptedResult ? 'accepted-result' : '',
              result.acceptedResult && released ? 'released-accepted-result' : ''
            ]"
                            #previousResult
                            data-cy="previous-result">
                        <div luxLayout="row" luxLayoutAlign="space-between center">
              <span luxFlex="33%" data-cy="run-result-value" *ngIf="!result.noResult" class="run-result">{{
                  result.value
                  }}</span>

                            <span luxFlex="33%" data-cy="run-result-value" *ngIf="result.noResult" class="run-result">
                <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                  <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
                  <use xlink:href="./assets/icons/spot_icons.svg#blocked"></use>
                </svg>
              </span>

                            <span luxFlex="33%" class="run-range">
                <lux-clinical-reference
                        luxFill
                        [indicator]="result?.relevanceIndicator"
                        [clinicalReference]="selectedAssay?.clinicalReference"></lux-clinical-reference>
              </span>

                            <!-- THIS IS NOT A BUTTON -->
                            <button
                                    *ngIf="result.acceptedResult"
                                    [disabled]="selectedAssay.status === 'CANCELED'"
                                    class="spot-button spot-button--primary spot-button--disabled spot-button--icon-only spot-button--with-icon accepted-result-indicator">
                                <svg class="spot-icon spot-button__icon spot-button__icon--left"
                                     aria-labelledby="title">
                                    <title>{{ 'LABELS.ACCEPTED_RESULT' | translate }}</title>
                                    <use xlink:href="./assets/icons/spot_icons.svg#checkmark"></use>
                                </svg>
                            </button>
                            <!-- /THIS IS NOT A BUTTON -->

                            <button
                                    *ngIf="!result.acceptedResult && result._links?.acceptResult"
                                    (click)="acceptResult(result)"
                                    [disabled]="selectedAssay.status === 'CANCELED'"
                                    title="{{ 'LABELS.ACCEPT' | translate }}"
                                    data-cy="choose-me-link"
                                    class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
                                <svg class="spot-icon spot-button__icon spot-button__icon--left"
                                     aria-labelledby="title">
                                    <title>{{ 'LABELS.ACCEPT' | translate }}</title>
                                    <use xlink:href="./assets/icons/spot_icons.svg#checkmark"></use>
                                </svg>
                            </button>
                        </div>

                        <div luxLayout="row" luxLayoutAlign="space-between center">
              <span>
                <span
                        data-cy="run-result-transformed-value"
                        class="run-result-transformed-value"
                        *ngIf="result?.transformedValue"
                >{{ result?.transformedValue }}</span
                >
              </span>
                        </div>

                        <div luxLayout="row" luxLayoutAlign="space-between center">
                            <div class="instrument-info" luxLayout="column">
                <span class="run-result-source" data-cy="result-source">
                  <span>{{ getAssayResultEnteredByDisplayName(result) }}</span>
                </span>

                                <span data-cy="result-timestamp" *ngIf="lab" class="run-time">{{
                                    result.timestamp | datePipe : lab
                                    }}</span>
                            </div>

                            <span class="error-flags">
                <span
                        *ngFor="let flag of result?.flags"
                        class="flag"
                        data-cy="assay-details-instrument-flag"
                        title="{{ flag.message }}"
                >{{ flag.code }}</span
                >
              </span>
                        </div>
                    </div>
                </div>
                <!-- /PREVIOUS RESULTS -->
            </div>
        </div>
        <!-- /ASSAY DETAIL -->

        <!-- BATCHED SELECTION DETAILS -->
        <div id="batched-assays" *ngIf="batchedAssays.length">
            <div
                    luxLayout="column"
                    *ngFor="let assay of batchedAssays"
                    id="batched-item-selections"
                    data-cy="batched-item-selections">
                <div luxFlex luxLayout="row" class="batched-item" data-cy="batched-item">
                    <span luxFlex="90%" data-cy="batched-item-testcode" class="testcode">{{ assay.testCode }}</span>
                    <svg
                            tabindex="0"
                            #batchDelete
                            (keydown.enter)="removeBatchedAssay(assay)"
                            (click)="removeBatchedAssay(assay)"
                            data-cy="batched-item-delete"
                            luxFlex="10%"
                            class="spot-icon spot-link__icon spot-link__icon--left"
                            aria-labelledby="title">
                        <title>{{ 'LABELS.CANCELED' | translate }}</title>
                        <use xlink:href="./assets/icons/spot_icons.svg#cancel"></use>
                    </svg>
                </div>
            </div>
            <div id="batched-assays-actions">
                <!-- BATCHED ASSAY ACTIONS-->
                <div
                        luxLayout="row"
                        id="batched-assay-action-buttons"
                        data-cy="batched-assay-action-buttons"
                        luxLayoutAlign="end center"
                        luxLayoutGap="20px">
                    <button
                            id="batch-repeat"
                            data-cy="batch-repeat-button"
                            [disabled]="shouldDisableBatchedRepeat()"
                            (click)="triggerBatchRepeat()"
                            title="{{ 'LABELS.REPEAT' | translate }}"
                            class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
                        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                            <title>{{ 'LABELS.REPEAT' | translate }}</title>
                            <use xlink:href="./assets/icons/spot_icons.svg#refresh-redo"></use>
                        </svg>
                    </button>
                    <button
                            id="batch-no-result-btn"
                            data-cy="batch-no-result-button"
                            [disabled]="shouldDisableBatchedNoResult()"
                            (click)="triggerBatchNoResult()"
                            title="{{ 'LABELS.NO_RESULT' | translate }}"
                            class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
                        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                            <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
                            <use xlink:href="./assets/icons/spot_icons.svg#blocked"></use>
                        </svg>
                    </button>
                    <button
                            id="batch-remove-btn"
                            #batchRemoveAll
                            data-cy="batch-remove-button"
                            (click)="removeAllBatchedAssays()"
                            title="{{ 'LABELS.DELETE' | translate }}"
                            class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
                        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                            <title>{{ 'LABELS.DELETE' | translate }}</title>
                            <use xlink:href="./assets/icons/spot_icons.svg#delete"></use>
                        </svg>
                    </button>
                </div>
                <div luxLayout="row">
                    <lux-comments
                            luxFlex
                            [lab]="lab"
                            [commentsDataSource]="commentsDataSource"
                            (addComment)="triggerAddingBatchComment($event)"
                            [placeholder]="'LABELS.COMMENTS_SEARCH' | translate"
                            [expandable]="true"
                            (updateContext)="handleAssayUpdated($event)"
                            data-cy="batch-comments"
                            #resultComment></lux-comments>
                </div>
            </div>
            <!-- / BATCHED ASSAY ACTIONS -->
        </div>
        <!-- /BATCHED SELECTION DETAILS -->
    </div>
</div>
