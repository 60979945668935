<div id="assay-cards-wrapper" luxFlex luxLayoutAlign="center start">
  <div id="assay-cards" *ngIf="accession && workspace" data-cy="assay-card-wrapper" luxLayout="row wrap">
    <app-assay-card
      *ngFor="let assay of accession.assays; index as i"
      [lab]="lab"
      [assay]="assay"
      [firstCard]="i === 0"
      [lastCard]="i === accession.assays.length - 1"
      [selectedAssay]="selectedAssay"
      [isBatched]="batchedAssays.includes(assay)"
      [preventResultInput]="preventInput()"
      (batch)="transmitBatchedCardSelection(assay, $event)"
      (selectAssay)="transmitAssayCardSelection($event)"
      (focusAssayDetails)="transmitFocusAssayDetails()"
      (assayUpdated)="assayUpdated.emit($event)"
      data-cy="assay-card">
    </app-assay-card>
  </div>
</div>
