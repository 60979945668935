import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, switchMap, Observable, of } from 'rxjs';
import { WorkspaceAccessionService } from '../workspace-accession.service';
import { AccessionsService } from './accessions.service';
import { QueueInformation } from './accession.component';
import { AppStateService, ChemistryAccession } from '../../app-state.service';
import { WorkspaceQueueService } from '../workspace-queue.service';
import { AuditService } from '@lims-common-ux/lux';
import { Assay } from '../../shared/interfaces/assay.interface';

@Injectable({ providedIn: 'root' })
export class QueueAccessionResolver {
  constructor(
    private appStateService: AppStateService,
    private accessionService: AccessionsService,
    private workQueuesService: WorkspaceQueueService,
    private workspaceAccessionService: WorkspaceAccessionService,
    private auditService: AuditService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QueueInformation> {
    const loadedQueueInfo = new QueueInformation();
    // This comes from the workspace resolver, which is always resolved before this as part of the route.
    loadedQueueInfo.workspace = route.parent.data.workspace;
    // match the current workspace to an item in the workspace queue list
    const workQueue = this.appStateService.workQueues.find((wq) => wq.workspaceId === loadedQueueInfo.workspace.id);

    if (workQueue) {
      return this.workQueuesService.getFirstQueueItem(workQueue._links.first).pipe(
        switchMap((queueResponse) => {
          // no items in the queue == empty response (204)
          if (queueResponse) {
            loadedQueueInfo.nextLink = queueResponse._links?.next;

            return this.accessionService
              .loadAccession(loadedQueueInfo.workspace._links.accession, queueResponse['accessionId'])
              .pipe(
                switchMap((accessionHeader) => {
                  loadedQueueInfo.headerAccessionInfo = accessionHeader;
                  return this.accessionService.loadChemistryAccession(accessionHeader, loadedQueueInfo.workspace);
                }),
                switchMap((accession: ChemistryAccession) => {
                  loadedQueueInfo.accession = accession;
                  return this.workspaceAccessionService.getAssays(accession);
                }),
                map((assays: Assay[]) => {
                  this.auditService.fireAccessionLoaded(loadedQueueInfo.accession.accessionId);
                  loadedQueueInfo.accession.assays = assays;
                  loadedQueueInfo.assays = assays;
                  return loadedQueueInfo;
                })
              );
          } else {
            loadedQueueInfo.empty = true;
            this.workQueuesService.navigateToEmptyQueue(this.appStateService.lab.id, loadedQueueInfo.workspace.id);
            return of(loadedQueueInfo);
          }
        })
      );
    } else {
      loadedQueueInfo.empty = true;
      return of(loadedQueueInfo);
    }
  }
}
