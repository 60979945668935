import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { Assay } from '../../../shared/interfaces/assay.interface';
import { Result } from '../../../shared/interfaces/result.interface';

import { Comment, CommentsComponent, Lab, Workspace } from '@lims-common-ux/lux';
import { AssaysService } from '../assays.service';
import { BatchedAssaySelection } from '../../../shared/interfaces/batchedAssaySelection.interface';
import { AppStateService, ChemistryAccession } from '../../../app-state.service';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';

@Component({
  selector: 'app-assay-details',
  templateUrl: './assay-details.component.html',
  styleUrls: ['./assay-details.component.scss'],
})
export class AssayDetailsComponent implements OnChanges {
  @ViewChild('cmpWrapper', { static: false }) cmpWrapper: ElementRef; // wrapping node
  @ViewChild('validateAssay', { static: false }) validateAssay: ElementRef;
  @ViewChild('workspaceComment', { static: false }) workspaceComment;
  @ViewChild('resultComment', { static: false })
  resultComment!: CommentsComponent;
  @ViewChild('batchRemoveAll', { static: false }) batchRemoveAllBtn: ElementRef;
  @ViewChildren('previousResult') previousResults: QueryList<ElementRef>;

  @Input()
  commentsDataSource: string;

  @Input()
  accession: ChemistryAccession;
  @Input()
  headerAccession: Accession;
  @Input()
  workspace: Workspace;
  @Input()
  selectedAssay: Assay;
  @Input()
  showNextLink: boolean;
  @Input()
  batchedAssays: Assay[] = [];
  @Input()
  lab: Lab;
  @Input()
  released: boolean;

  @Output()
  escapeResultEntry: EventEmitter<void> = new EventEmitter();
  @Output()
  getNextQueueAccession: EventEmitter<void> = new EventEmitter();
  @Output()
  acceptAllResults: EventEmitter<void> = new EventEmitter();
  @Output()
  batchNoResult: EventEmitter<void> = new EventEmitter();
  @Output()
  batchRepeat: EventEmitter<void> = new EventEmitter();
  @Output()
  batchComment: EventEmitter<Comment> = new EventEmitter<Comment>();
  @Output()
  deleteBatchedItem: EventEmitter<BatchedAssaySelection> = new EventEmitter<BatchedAssaySelection>();
  @Output()
  removeAllBatchedItems: EventEmitter<void> = new EventEmitter();
  @Output()
  noResultAssay: EventEmitter<void> = new EventEmitter();
  @Output()
  repeatAssay: EventEmitter<void> = new EventEmitter();
  @Output()
  acceptAssay: EventEmitter<Result> = new EventEmitter();
  @Output()
  assayUpdated: EventEmitter<Assay> = new EventEmitter();

  disableAcceptAll = true;

  constructor(public assaysService: AssaysService, public appStateService: AppStateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.workspaceComment) {
      this.workspaceComment.nativeElement.value = '';
    }
    this.setAcceptAllButtonDisabled();
  }

  onAddComment(comment: Comment) {
    if (comment && !this.assaysService.pending && this.selectedAssay._links?.addComment) {
      this.appStateService.loading = true;
      this.assaysService.addComment(this.selectedAssay, comment).subscribe(() => {
        this.appStateService.loading = false;
        this.resultComment.resetComments();
        this.handleAssayUpdated(this.selectedAssay);
      });
    }
  }

  onRemoveComment(comment: Comment) {
    if (!this.assaysService.pending && !!comment._links?.removeComment) {
      this.appStateService.loading = true;
      this.assaysService.removeComment(comment).subscribe(() => {
        this.appStateService.loading = false;
        this.handleAssayUpdated(this.selectedAssay);
      });
    }
  }

  focusSelectedAssayEntryControl() {
    setTimeout(() => {
      if (
        this.selectedAssay &&
        this.selectedAssay.currentResult &&
        !this.selectedAssay.currentResult.acceptedResult &&
        !this.batchedAssays.length
      ) {
        this.focusValidateAssay();
      } else if (!this.selectedAssay && !this.batchedAssays.length) {
        this.focusWorkspaceComment();
      }
    }, 0);
  }

  handleEscape($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.escapeResultEntry.emit();
  }

  nextInQueue() {
    this.getNextQueueAccession.emit();
  }

  focusValidateAssay() {
    setTimeout(() => {
      this.validateAssay.nativeElement.focus();
    }, 0);
  }

  focusResultComment() {
    setTimeout(() => {
      this.resultComment.focusSearchInput();
    }, 0);
  }

  focusWorkspaceComment() {
    if (this.workspaceComment && this.workspaceComment.nativeElement) {
      setTimeout(() => {
        this.workspaceComment.nativeElement.focus();
      }, 0);
    }
  }

  acceptResult(result: Result) {
    this.acceptAssay.emit(result);
  }

  noResult() {
    this.noResultAssay.emit();
  }

  repeat() {
    this.repeatAssay.emit();
  }

  handleAssayUpdated(assay: Assay) {
    this.appStateService.loading = true;
    this.assaysService.getUpdatedAssay(assay).subscribe(
      (res) => {
        this.appStateService.loading = false;
        this.assayUpdated.emit(res);
      },
      (error) => {
        throw error;
      }
    );
  }

  triggerAcceptAll() {
    this.acceptAllResults.emit();
  }

  setAcceptAllButtonDisabled() {
    if (this.accession && this.accession.assays && this.accession.assays.length) {
      const acceptableAssays: Assay[] = this.accession.assays.filter((assay) => {
        return assay && assay.currentResult && assay.currentResult._links && assay.currentResult._links.acceptResult;
      });

      this.disableAcceptAll = acceptableAssays.length < 1;
    }
  }

  shouldDisableBatchedNoResult() {
    let disabled = false;

    if (this.batchedAssays && this.batchedAssays.length) {
      this.batchedAssays.forEach((assay: Assay) => {
        if (!assay._links.markAsNoResult && disabled !== true) {
          disabled = true;
        }
      });

      return disabled;
    } else {
      return true;
    }
  }

  shouldDisableBatchedRepeat() {
    let disabled = false;

    if (this.batchedAssays && this.batchedAssays.length) {
      this.batchedAssays.forEach((assay: Assay) => {
        if (!assay._links.requestRepeat && disabled !== true) {
          disabled = true;
        }
      });

      return disabled;
    } else {
      return true;
    }
  }

  removeBatchedAssay(assay: Assay) {
    this.deleteBatchedItem.emit({
      assay: assay,
      batched: false,
    });
  }

  removeAllBatchedAssays() {
    this.removeAllBatchedItems.emit();
  }

  triggerBatchNoResult() {
    this.batchNoResult.emit();
  }

  triggerBatchRepeat() {
    this.batchRepeat.emit();
  }

  triggerAddingBatchComment(comment: Comment) {
    if (comment) {
      this.batchComment.emit(comment);
    }
  }

  getAssayResultEnteredByDisplayName(result: Result): string {
    if (result.instrument) {
      return result.instrument.name;
    } else {
      return result.enteredBy;
    }
  }
}
