import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Assay } from '../../../../shared/interfaces/assay.interface';
import { AppStateService } from '../../../../app-state.service';
import { StringUtilService } from '@lims-common-ux/lux';
import { AssaysService } from '../../assays.service';

@Component({
  selector: 'app-result-input',
  templateUrl: './result-input.component.html',
  styleUrls: ['./result-input.component.scss'],
})
export class ResultInputComponent implements OnDestroy {
  @ViewChild('input', { static: false }) input: ElementRef;

  @Input()
  assay: Assay;

  @Output()
  inputBlurred: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  space: EventEmitter<true> = new EventEmitter<true>();
  @Output()
  assayUpdated = new EventEmitter<Assay[]>();

  showResultValueError: boolean;
  runValueUpdated: boolean;
  addResultSub: Subscription;

  constructor(
    public appState: AppStateService,
    public strService: StringUtilService,
    public assaysService: AssaysService
  ) {}

  ngOnDestroy(): void {
    if (this.addResultSub) {
      this.addResultSub.unsubscribe();
    }
  }

  focus() {
    this.input.nativeElement.focus();
  }

  handleSpace($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.space.emit(true);
  }

  handleInput($event) {
    if (this.runValueUpdated) {
      $event.preventDefault();
      $event.stopPropagation();
    } else {
      const value = this.input.nativeElement.value.trim();
      this.showResultValueError = false;
      if (value) {
        this.showResultValueError = !this.isValidResultValue(value);
      }
    }
  }

  handleBlur() {
    this.reset();
    this.inputBlurred.emit();
  }

  isValidResultValue(value: string): boolean {
    return Boolean(this.strService.toNormalizedResultValue(value));
  }

  trimLeadingZeros(value) {
    return this.strService.toNormalizedResultValue(value);
  }

  handleResultAdd($event) {
    let value = this.input.nativeElement.value.trim();

    if (value && !this.showResultValueError && !this.runValueUpdated) {
      this.runValueUpdated = true;

      this.appState.loading = true;

      value = this.trimLeadingZeros(value);

      this.input.nativeElement.value = value;

      this.addResultSub = this.assaysService.addResult(this.assay, value).subscribe(
        (res) => {
          this.assayUpdated.emit(res);
          this.runValueUpdated = false;
          setTimeout(() => {
            this.appState.loading = false;
          });
          this.reset();
        },
        (error) => {
          this.runValueUpdated = false;
          throw error;
        }
      );
    } else {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  reset() {
    this.input.nativeElement.value = '';
    this.showResultValueError = false;
  }
}
