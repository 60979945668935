import { Component, OnDestroy, OnInit } from '@angular/core';
import { KeyboardAction, KeyboardService, ModalContainerService } from '@lims-common-ux/lux';
import { AppStateService } from '../../app-state.service';
import { KeyboardHelpItem } from '@lims-common-ux/lux/lib/keyboard-help-category/keyboard-help-category.component';
import { NavigationStart, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
})
export class HelpModalComponent implements OnInit, OnDestroy {
  private openModal: KeyboardAction = {
    name: 'open-modal-help',
    matchCallback: ($evt: KeyboardEvent) => {
      if (this.modalService.openModal) {
        return;
      }
      this.open();
    },
  };

  private closeModal: KeyboardAction = {
    name: 'modal-close',
    eventMatch: {
      key: 'Escape',
    },
    matchCallback: ($event: KeyboardEvent) => {
      if (this.appStateService.loading) {
        return;
      }
      this.keyboardService.preventDefaultAndPropagation($event);
      this.close();
    },
    removeOnMatch: true,
  };

  visible: boolean;

  keyboardHelpItemsContext: KeyboardHelpItem[] = [
    {
      description: 'HELP.ASSAY_ACCEPT',
      bindings: ['SHORTCUTS.F4'],
    },
    {
      description: 'HELP.ASSAY_REPEAT',
      bindings: ['SHORTCUTS.F5'],
    },
    {
      description: 'HELP.ASSAY_COMMENTS',
      bindings: ['SHORTCUTS.F6'],
    },
    {
      description: 'HELP.ASSAY_NO_RESULT',
      bindings: ['SHORTCUTS.F8'],
    },
    {
      description: 'HELP.ASSAY_ACCEPT_ALL',
      bindings: ['SHORTCUTS.F11'],
    },
    {
      description: 'HELP.MANUAL_ENTRY_TOGGLE',
      bindings: ['SHORTCUTS.SHIFT_ENTER'],
    },
    {
      description: 'HELP.ASSAY_BULK_SELECT',
      bindings: ['SHORTCUTS.SPACE'],
    },
  ];
  keyboardHelpItemsNav: KeyboardHelpItem[] = [
    {
      description: 'HELP.ASSAY_FIRST_FOCUS',
      bindings: ['SHORTCUTS.F1'],
    },
    {
      description: 'HELP.NAV_NEXT',
      bindings: ['SHORTCUTS.ARROW_DOWN', 'SHORTCUTS.TAB'],
    },
    {
      description: 'HELP.NAV_PREV',
      bindings: ['SHORTCUTS.ARROW_UP', 'SHORTCUTS.SHIFT_TAB'],
    },
    {
      description: 'HELP.NAV_NEXT_QUEUE_ITEM',
      bindings: ['SHORTCUTS.ALT_ARROW_RIGHT'],
    },
    {
      description: 'HELP.RETURN_FOCUS',
      bindings: ['SHORTCUTS.ESCAPE'],
    },
  ];
  keyboardHelpItemsForms: KeyboardHelpItem[] = [
    {
      description: 'HELP.FORM_CONTROL_TOGGLE',
      bindings: ['SHORTCUTS.SPACE'],
    },
  ];
  keyboardHelpItemsGeneral: KeyboardHelpItem[] = [
    {
      description: 'HELP.MENU',
      bindings: ['SHORTCUTS.F2'],
    },
    {
      description: 'LABELS.LAB_NOTES',
      bindings: ['SHORTCUTS.F9'],
    },
    {
      description: 'LABELS.ACCESSION_INFORMATION',
      bindings: ['SHORTCUTS.ALT_A'],
    },
    {
      description: 'HELP.HELP',
      bindings: ['SHORTCUTS.ALT_H'],
    },
    {
      description: 'LABELS.ADVANCED_ACCESSION_SEARCH',
      bindings: ['SHORTCUTS.ALT_F'],
    }
  ];
  routerEventSub: Subscription;

  constructor(
    private appStateService: AppStateService,
    private modalService: ModalContainerService,
    private keyboardService: KeyboardService,
    router: Router
  ) {
    this.routerEventSub = router.events
      .pipe(
        filter((e) => {
          return e instanceof NavigationStart;
        })
      )
      .subscribe(() => {
        this.close();
      });
  }

  ngOnInit(): void {
    this.keyboardService.addActions([this.openModal]);
  }

  ngOnDestroy() {
    this.routerEventSub.unsubscribe();
  }

  open() {
    this.keyboardService.addActions([this.closeModal]);
    this.visible = true;
  }

  close() {
    this.visible = false;
    this.appStateService.triggerHelpModalClosed();
  }
}
