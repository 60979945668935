import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '../../app-state.service';
import { Observable, of } from 'rxjs';
import { Workspace } from '@lims-common-ux/lux';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WorkspaceResolver  {
  constructor(private appStateService: AppStateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Workspace> {
    const workspaceId = route.paramMap.get('workspaceId');
    const workspace = this.appStateService.workspaces.find(
      (ws) => ws.id === workspaceId && ws.workspaceType === 'CHEMISTRY'
    );
    return of(workspace);
  }
}
