import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { BatchedAssaySelection } from '../../shared/interfaces/batchedAssaySelection.interface';
import { Assay } from '../../shared/interfaces/assay.interface';

import { Lab, Workspace } from '@lims-common-ux/lux';
import { AssayCardComponent } from '../assay/assay-card/assay-card.component';
import { ChemistryAccession } from '../../app-state.service';

@Component({
  selector: 'app-assay-cards',
  templateUrl: './assay-cards.component.html',
  styleUrls: ['./assay-cards.component.scss'],
})
export class AssayCardsComponent {
  @ViewChildren(AssayCardComponent) assayCards!: QueryList<AssayCardComponent>;

  @Input()
  workspace: Workspace;
  @Input()
  accession: ChemistryAccession;
  @Input()
  selectedAssay: Assay = null;
  @Input()
  batchedAssays: Assay[] = [];
  @Input()
  readOnly = false;
  @Input()
  lab: Lab;

  @Output()
  selectAssay: EventEmitter<Assay> = new EventEmitter();
  @Output()
  assayUpdated: EventEmitter<Assay[]> = new EventEmitter();
  @Output()
  batchTransmission: EventEmitter<BatchedAssaySelection> = new EventEmitter();
  @Output()
  focusAssayDetails: EventEmitter<void> = new EventEmitter<void>();

  // Bubble card selection up to parent to update sibling
  transmitAssayCardSelection(assay: Assay) {
    this.selectAssay.emit(assay);
  }

  transmitFocusAssayDetails() {
    this.focusAssayDetails.emit();
  }

  transmitBatchedCardSelection(assay: Assay, isBatched: boolean) {
    this.batchTransmission.emit({
      assay: assay,
      batched: isBatched,
    });
  }

  // Focus card corresponding to appState selectedAssay, or first card
  focusSelectedAssayCard() {
    if (this.selectedAssay) {
      this.assayCards
        .filter((card) => card.assay.testCode === this.selectedAssay.testCode)[0]
        .displayValueLink.nativeElement.focus();
    } else {
      this.assayCards.first.displayValueLink.nativeElement.focus();
    }
  }

  preventInput(): boolean {
    return this.batchedAssays.length > 1 || this.readOnly;
  }
}
