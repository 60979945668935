import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStateService, ChemistryAccession } from '../app-state.service';
import { Link } from '../shared/interfaces/link.interface';
import { AssaysService } from './assay/assays.service';
import { map, Observable } from 'rxjs';
import { Assay } from '../shared/interfaces/assay.interface';
import { Workspace } from '@lims-common-ux/lux';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';

/**
 * Intermediary class for getting Accession info from a particular workspace. Should not be used outside of this service.
 *
 */
export interface WorkspaceAccessionInfo {
  accessionNumber: string;
  assays: string[];
  _links?: {
    batchAccept?: Link;
    batchNoResult?: Link;
    assays?: Link;
  };
}

interface AssayResponse {
  _embedded: { assays: Assay[] };
}

@Injectable({
  providedIn: 'root',
})
export class WorkspaceAccessionService {
  constructor(
    private appStateService: AppStateService,
    public http: HttpClient,
    private assaysService: AssaysService
  ) {}

  loadAccession(accessionLink: Link, accessionId: string): Observable<Accession> {
    const accessionUrl = accessionLink.href.replace('{accessionId}', accessionId);
    return this.http.get<Accession>(accessionUrl);
  }

  /**
   * Assume we are loading a Chemistry Workspace here as we shouldn't be getting details on any other workspace in this
   * context.
   */
  loadWorkspaceAccession(accession: Accession, currentWorkspace: Workspace): Observable<ChemistryAccession> {
    const accessionWorkspace = accession._embedded.workspaces.filter((ws) => ws.id === currentWorkspace.id)[0];
    return this.http.get<ChemistryAccession>(accessionWorkspace._links.accession.href);
  }

  getAssays(accession: ChemistryAccession): Observable<Assay[]> {
    return this.http.get<AssayResponse>(accession._links.assays.href).pipe(
      map((response: AssayResponse) => {
        if (response._embedded?.assays?.length) {
          response._embedded.assays.forEach((assay) => {
            // Sort the results on individual assay by timestamp
            if (assay.results && assay.results.length > 1) {
              this.assaysService.sortResultsByTimestamp(assay);
            }
          });
          return response._embedded.assays;
        } else {
          return [];
        }
      })
    );
  }
}
