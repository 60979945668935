<!-- RUN RESULT -->
<div class="spot-form__field-group" [class.spot-form--error]="showResultValueError">
  <input
    #input
    (blur)="handleBlur()"
    (input)="handleInput($event)"
    (keydown.enter)="handleResultAdd($event)"
    (keydown.space)="handleSpace($event)"
    autocomplete="off"
    class="run-result-input spot-form__input"
    data-cy="manual-result-input"
    name="runResultValue"
    type="text" />
  <span *ngIf="showResultValueError" class="spot-form__field-error" id="error-must-be-number" role="alert">
    <span class="spot-form__field-error-text" data-cy="result-value-error"
      >{{ 'ERRORS_AND_FEEDBACK.MUST_BE_NUM' | translate }}
    </span>
  </span>
</div>
<!-- RUN RESULT -->
