import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Workspace } from '@lims-common-ux/lux';
import { ChemistryAccession } from '../../../app-state.service';
import { Accession as AccessionHeader } from '@lims-common-ux/lux/lib/accession/accession.interface';

@Component({
  selector: 'app-workspace-navigation',
  templateUrl: './workspace-navigation.component.html',
  styleUrls: ['./workspace-navigation.component.scss'],
})
export class WorkspaceNavigationComponent {
  @Input()
  workspaceAccession: ChemistryAccession;

  @Input()
  headerAccession: AccessionHeader;

  @Input()
  currentWorkspace: Workspace;

  @Input()
  showAcceptAll: boolean;

  @Input()
  disableAcceptAll: boolean;

  @Output()
  acceptAll: EventEmitter<void> = new EventEmitter<void>();

  acceptAllResults() {
    this.acceptAll.emit();
  }
}
