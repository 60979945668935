import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mapTo, shareReplay, tap, Observable } from 'rxjs';

import { ChemistryAccession } from '../../app-state.service';
import { Accession } from '../../shared/interfaces/accession.interface';

import { WorkspaceAccessionInfo, WorkspaceAccessionService } from '../workspace-accession.service';
import { UrlUtil, Workspace } from '@lims-common-ux/lux';
import { Link } from '../../shared/interfaces/link.interface';
import { Assay } from '../../shared/interfaces/assay.interface';
import { PageResource } from '../../interfaces/pageResource.interface';
import { PAGE_RESOURCE } from '../../application-init.service';

/**
 * Used when searching by barcode or accession number.
 */
export interface AccessionSearchRes {
  _embedded?: {
    accessions?: Accession[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class AccessionsService {
  constructor(
    @Inject(PAGE_RESOURCE) private pageResource: PageResource,
    private workspacesService: WorkspaceAccessionService,
    private http: HttpClient
  ) {}

  /**
   * @param accessionLink Accession link obtained either from the Results Page resource or a Workspace resource
   * @param accessionId
   */
  loadAccession(accessionLink: Link, accessionId: string): Observable<Accession> {
    const accessionUrl = accessionLink.href.replace('{accessionId}', accessionId);
    return this.http.get<Accession>(accessionUrl).pipe(shareReplay());
  }

  /**
   * @param accession Accession obtained from Workspace Manager
   * @param currentWorkspace
   */
  loadChemistryAccession(accession: Accession, currentWorkspace: Workspace): Observable<ChemistryAccession> {
    return this.http.get<ChemistryAccession>(
      UrlUtil.interpolateUrl(this.pageResource._links.accession.href, {
        workspaceId: currentWorkspace.id,
        accessionId: accession.id,
      })
    );
  }

  /**
   * Used by the queue service where we have the full accession link provided.
   * @param accessionLink
   */
  loadAccessionHeader(accessionLink: Link): Observable<Accession> {
    return this.http.get<Accession>(accessionLink.href).pipe(shareReplay());
  }

  updateAssays(accession: ChemistryAccession, assays: Assay[]) {
    for (let i = 0; i < accession.assays.length; i++) {
      const updated = assays.find((a) => a.testCode === accession.assays[i].testCode);
      if (updated) {
        accession.assays[i] = updated;
      }
    }
  }

  findWorkspace(workspaceId: string, accession: Accession): Workspace {
    return accession._embedded.workspaces.find((wrk) => wrk.id === workspaceId);
  }

  /**
   *
   * @param workspace
   * @param forAccession
   */
  addWorkspaceDetails(workspace: Workspace, forAccession: Accession): Observable<Accession> {
    const link = workspace._links.accession;
    return this.http.get<WorkspaceAccessionInfo>(link.href).pipe(
      tap((accessionWorkspaceInfo) => {
        forAccession._links = accessionWorkspaceInfo._links;
      }),
      mapTo(forAccession)
    );
  }
}
