import { Result } from './result.interface';
import { Comment } from '@lims-common-ux/lux';
import { Link } from './link.interface';

export interface Assay {
  testCode: string;
  status: AssayStatus;
  version: number;
  results: Result[];
  resultRequired: ResultRequired;
  comments: Comment[];
  currentResult?: Result;
  clinicalReference?: string;
  // This is left as `any` on purpose. Client shouldn't get into details here as this is used as an identifier by
  // the server and we don't need to get into the details here.
  batchItem?: any;
  expectedPerformingLab: string;
  _links: {
    addResult: Link;
    requestRepeat: Link;
    addComment: Link;
    markAsNoResult: Link;
    self: Link;
  };
}

export enum ResultRequired {
  ESSENTIAL = 'ESSENTIAL',
  NON_ESSENTIAL = 'NON_ESSENTIAL',
}

export enum AssayStatus {
  CANCELED = 'CANCELED',
  OPENED = 'OPENED',
  RELEASED = 'RELEASED',
  REPEAT_REQUESTED = 'REPEAT_REQUESTED',
  RESULT_RECEIVED = 'RESULT_RECEIVED',
  TECHNICALLY_ACCEPTED = 'TECHNICALLY_ACCEPTED',
  TECHNICIAN_REVIEW = 'TECHNICIAN_REVIEW',
}
