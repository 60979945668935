import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WorkspaceComponent } from './workspace/workspace.component';
import { AccessionComponent } from './accession/accession.component';
import { RouterModule } from '@angular/router';
import { WorkspaceAccessionService } from './workspace-accession.service';
import { AssayCardComponent } from './assay/assay-card/assay-card.component';
import { ApplicationPipesModule, LabNotesComponent, LUX, LuxLayoutModule, SamplesModule, SnackbarModule, WorkspacesModule } from '@lims-common-ux/lux';
import { AssaysService } from './assay/assays.service';
import { TranslateModule } from '@ngx-translate/core';
import { AssayDetailsComponent } from './assay/assay-details/assay-details.component';
import { AssayCardsComponent } from './assay-cards/assay-cards.component';
import { ResultInputComponent } from './assay/assay-card/result-input/result-input.component';
import { WorkspaceNavigationComponent } from './workspace/workspace-navigation/workspace-navigation.component';
import { WorkspaceQueueService } from './workspace-queue.service';
import { EmptyQueueComponent } from './empty-queue/empty-queue.component';

@NgModule({
  declarations: [
    WorkspaceComponent,
    AccessionComponent,
    AssayCardComponent,
    AssayDetailsComponent,
    AssayCardsComponent,
    ResultInputComponent,
    WorkspaceNavigationComponent,
    EmptyQueueComponent,
  ],
  imports: [
    TranslateModule.forChild({}),
    FormsModule,
    CommonModule,
    RouterModule,
    LUX,
    ApplicationPipesModule,
    LuxLayoutModule,
    SnackbarModule,
    SamplesModule,
    WorkspacesModule,
    LabNotesComponent,
  ],
  providers: [WorkspaceQueueService, WorkspaceAccessionService, AssaysService],
  exports: [WorkspaceNavigationComponent],
})
export class WorkspaceModule {}
