/* ANGULAR DEPENDENCIES */
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
/* LAYOUT AND TRANSLATION */
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
/* APPLICATION LEVEL DEPENDENCIES */
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppRoutingModule } from './app-routing.module';
import { AppStateService } from './app-state.service';
import { appSettings } from '../environments/app-settings';

/* WORKSPACES AND QUEUES */
import {
  AccessionModule,
  DataResourcesModule,
  FeaturesModule,
  FlyoutModule,
  GlobalErrorHandlerModule,
  GlobalErrorHandlerService,
  KeyboardHelpCategoryModule,
  KeyboardModule,
  LabRequestInterceptor,
  LUX,
  SnackbarModule,
  WorkQueuesModule,
  WorkspacesModule,
  LuxLayoutModule,
} from '@lims-common-ux/lux';
import { ApplicationInitService } from './application-init.service';
import { WorkspaceModule } from './workspace/workspace.module';
import { StaticAppData } from './interfaces/application-data.interface';
import { LabModule } from './lab/lab.module';
import {
  MsalBroadcastService,
  MsalService,
  MsalModule,
  MsalInterceptor,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionStatus, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { Observable, filter, take } from 'rxjs';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  if (appSettings.redirectUri !== '/') {
    // library does not take base-href into account and always just goes to the top level /assets by default
    return new TranslateHttpLoader(http, `${appSettings.redirectUri}/assets/i18n/`);
  } else {
    return new TranslateHttpLoader(http);
  }
}

declare let CLIENT_ID: string;
declare let TENANT_ID: string;
declare let PROTECTED_RESOURCES: [string, string[]][];

function securityInitializer(broadcaster: MsalBroadcastService, service: MsalService): () => Observable<any> {
  return () => {
    // this is required to the service to complete its initialization. We need this to be done in an app initializer
    // because we often have data we need to retrieve that is protected, and this will take care of making sure those
    // calls are properly authenticated.
    service.handleRedirectObservable().subscribe(() => {
      // we don't care about the result value here, just let the library do its thing
    });

    return broadcaster.inProgress$.pipe(
      filter((progress) => progress === InteractionStatus.None),
      take(1) // the observable never completes normally, and we only need this to be hit once
    );
  };
}

const PROTECTED = new Map<string, Array<string>>(PROTECTED_RESOURCES);

export function applicationDataInitFactory(service: ApplicationInitService): () => Promise<StaticAppData> {
  return (): Promise<StaticAppData> => service.initialize();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    LUX,
    HttpClientModule,
    LuxLayoutModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: CLIENT_ID, // Application (client) ID from the app registration
          authority: 'https://login.microsoftonline.com/' + TENANT_ID + '/',
          redirectUri: appSettings.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(PROTECTED),
      }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DataResourcesModule,
    KeyboardModule,
    WorkspacesModule,
    FlyoutModule,
    WorkspaceModule,
    WorkQueuesModule,
    AccessionModule,
    AppRoutingModule, // top level app routing should be defined after all sub-module routes are configured
    SnackbarModule,
    GlobalErrorHandlerModule,
    RouterModule.forRoot([], {
      onSameUrlNavigation: 'reload',
      useHash: appSettings.useHash,
    }),
    KeyboardHelpCategoryModule,
    LabModule,
    FeaturesModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: securityInitializer,
      deps: [MsalBroadcastService, MsalService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: applicationDataInitFactory,
      deps: [ApplicationInitService],
      multi: true,
    },
    AppStateService,
    AppService,
    TranslatePipe,
    { provide: 'Window', useValue: window },
    { provide: 'Document', useValue: document },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LabRequestInterceptor,
      multi: true,
    },
    HttpClientModule,
    GlobalErrorHandlerService,
    {
      provide: ErrorHandler,
      useExisting: GlobalErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
