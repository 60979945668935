import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { WorkspaceAccessionService } from '../workspace-accession.service';
import { EMPTY, Observable, catchError, map, switchMap, tap, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AccessionsService } from './accessions.service';
import { AccessionInformation } from './accession.component';
import { AppStateService, ChemistryAccession } from '../../app-state.service';
import { AuditService, GlobalErrorHandlerService } from '@lims-common-ux/lux';
import { Assay } from '../../shared/interfaces/assay.interface';

@Injectable({ providedIn: 'root' })
export class AccessionResolver  {
  constructor(
    private appStateService: AppStateService,
    private accessionService: AccessionsService,
    private workspaceAccessionService: WorkspaceAccessionService,
    private globalErrorHandler: GlobalErrorHandlerService,
    private router: Router,
    private auditService: AuditService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AccessionInformation> {
    const accessionId = route.paramMap.get('accessionId');
    const loadedAccession = new AccessionInformation();
    // This comes from the workspace resolver, which is always resolved before this as part of the route.
    loadedAccession.workspace = route.parent.data.workspace;
    return this.accessionService.loadAccession(this.appStateService.accessionHeaderInfoLink, accessionId).pipe(
      catchError((err) => {
        this.router.navigate(['/', 'lims-results', this.appStateService.lab.id]).then(() => {
          this.globalErrorHandler.handleError(err);
        });
        return EMPTY;
      }),
      switchMap((headerAccessionInfo) => {
        // this can happen if the accession is performed in another lab and we are viewing it that labs workspace.
        if (!loadedAccession.workspace) {
          const pathWorkspace = route.parent.paramMap.get('workspaceId');
          loadedAccession.workspace = this.accessionService.findWorkspace(pathWorkspace, headerAccessionInfo);
          if (loadedAccession.workspace?.workspaceType !== 'CHEMISTRY') {
            this.router.navigate(['/', 'lims-results', this.appStateService.lab.id]).then(() => {
              this.globalErrorHandler.handleError(
                new Error(`Could not find chemistry workspace ${pathWorkspace} in accession ${headerAccessionInfo.id}`)
              );
            });
            return EMPTY;
          }
        }
        return of(headerAccessionInfo);
      }),
      switchMap((headerAccessionInfo) => {
        return this.accessionService.loadChemistryAccession(headerAccessionInfo, loadedAccession.workspace).pipe(
          catchError((err) => {
            this.router.navigate(['/', 'lims-results', this.appStateService.lab.id]).then(() => {
              this.globalErrorHandler.handleError(err);
            });
            return EMPTY;
          }),
          tap((accession) => {
            loadedAccession.headerAccessionInfo = headerAccessionInfo;
            loadedAccession.accession = accession;
          })
        );
      }),

      // Need workspace accession to be loaded before we get panels and assays. Panels and Assays can also
      // be loaded concurrently
      switchMap((accession: ChemistryAccession) => {
        return this.workspaceAccessionService.getAssays(accession);
      }),
      map((assays: Assay[]) => {
        this.auditService.fireAccessionLoaded(loadedAccession.headerAccessionInfo.id);
        loadedAccession.accession.assays = assays;
        loadedAccession.assays = assays;
        return loadedAccession;
      })
    );
  }
}
