import { Component, HostListener } from '@angular/core';
import { AppStateService } from './app-state.service';
import { KeyboardService, ModalContainerService } from '@lims-common-ux/lux';
import { GuardsCheckEnd, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { Subject, filter, takeUntil } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loggedIn = false;
  private onDestroy$ = new Subject<void>();

  @HostListener('document:keydown', ['$event'])
  onKeydown($evt: KeyboardEvent) {
    // Modals handle their own key events
    if (!!this.modalService.openModal) {
      return $evt;
    }

    // Prevent keyboard interactions while loading
    if (this.appStateService.loading) {
      this.keyboardService.preventDefaultAndPropagation($evt);
      return false;
    }

    if (!this.appStateService.loading) {
      this.keyboardService.handleAppKeydown($evt);
    }
  }

  constructor(
    private keyboardService: KeyboardService,
    private appStateService: AppStateService,
    private router: Router,
    private modalService: ModalContainerService,
    private title: Title,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.appStateService.loading = true;
      } else if (e instanceof NavigationEnd || e instanceof NavigationError) {
        this.appStateService.loading = false;
      } else if (e instanceof GuardsCheckEnd && !e.shouldActivate) {
        this.appStateService.loading = false;
      }
    });

    this.title.setTitle(this.appStateService.defaultPageTitle);

    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;

    this.broadcastService.inProgress$
      .pipe(
        filter((progress) => {
          return progress === InteractionStatus.None;
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
      });
  }

  handleAccessionRefresh() {
    window.location.reload();
  }
}
