import { Injectable } from '@angular/core';
import { Assay, AssayStatus } from '../../shared/interfaces/assay.interface';
import { HttpClient } from '@angular/common/http';
import { AppStateService, ChemistryAccession } from '../../app-state.service';
import { Link } from '../../shared/interfaces/link.interface';
import { EMPTY, concatMap, map, switchMap, tap, forkJoin, Observable, of, throwError } from 'rxjs';
import { Result } from '../../shared/interfaces/result.interface';
import { AssayReferenceResponse } from '../../shared/interfaces/assayReferenceResponse.interface';
import { Comment } from '@lims-common-ux/lux';

@Injectable({
  providedIn: 'root',
})
export class AssaysService {
  constructor(public appStateService: AppStateService, public http: HttpClient) {}

  pending = false;

  getAssayStatusPresentation(assay: Assay) {
    let statusPresentationClass = '';
    switch (assay.status) {
      case 'RESULT_RECEIVED':
      case 'OPENED':
        statusPresentationClass = 'attention';
        break;
      case 'REPEAT_REQUESTED':
        statusPresentationClass = 'repeat-requested';
        break;
      case 'TECHNICALLY_ACCEPTED':
        statusPresentationClass = 'technically-accepted';
        break;
      case 'CANCELED':
        statusPresentationClass = 'canceled';
        break;
      default:
        break;
    }

    if (assay?.currentResult?.noResult && assay.status !== AssayStatus.CANCELED) {
      statusPresentationClass = 'no-result';
    }

    return statusPresentationClass;
  }

  handleIndividualAssayModification(
    method: string,
    link: Link,
    request?: { body?: object; observe?: string }
  ): Observable<Assay[]> {
    const req = this.http[method](link.href, request && request.body);

    this.pending = true;

    return req.pipe(
      switchMap((res: AssayReferenceResponse) => {
        return this.loadUpdatedAssays(res);
      }),
      tap(() => (this.pending = false))
    );
  }

  handleBatchAssayModification(
    method: string,
    link: Link,
    request?: { body?: object; observe?: string }
  ): Observable<Assay[]> {
    const req = this.http[method](link.href, request && request.body);
    return req.pipe(
      concatMap((res: AssayReferenceResponse) => {
        return this.loadUpdatedAssays(res);
      })
    );
  }

  addResult(assay: Assay, resultValue: string): Observable<Assay[]> {
    if (!this.pending) {
      return this.handleIndividualAssayModification('post', assay._links.addResult, {
        body: {
          performingLabId: this.appStateService.lab.id,
          value: resultValue,
        },
      });
    } else {
      return EMPTY;
    }
  }

  loadUpdatedAssays(res: AssayReferenceResponse): Observable<Assay[]> {
    if (res.updated.length === 0) {
      return of([]); // need something to say it's done. ForkJoin on an empty list does not complete
    } else {
      return forkJoin(
        res.updated.map((item, i) => {
          return this.getUpdatedAssay(item);
        })
      );
    }
  }

  getUpdatedAssay(assay: { _links: { self: Link } }): Observable<Assay> {
    return this.http.get<Assay>(assay._links.self.href).pipe(
      tap((updated) => {
        this.sortResultsByTimestamp(updated);
      })
    );
  }

  sortResultsByTimestamp(assay: Assay): Assay {
    if (assay && assay.results && assay.results.length > 1) {
      assay.results.sort((a, b) => {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
      });
    }
    return assay;
  }

  acceptResult(result: Result): Observable<Assay[]> {
    return this.handleIndividualAssayModification('put', result._links.acceptResult);
  }

  acceptAllResults(accession: ChemistryAccession): Observable<Assay[]> {
    if (accession && accession.assays.length) {
      const acceptableAssays: any[] = accession.assays
        .filter((assay) => {
          return Boolean(
            assay && assay.currentResult && assay.currentResult._links && assay.currentResult._links.acceptResult
          );
        })
        .map((assay) => assay.currentResult.batchItem);
      return this.handleBatchAssayModification('put', accession._links.batchAccept, {
        body: { batchItems: acceptableAssays },
      });
    }
  }

  batchNoResult(accession: ChemistryAccession, selectedBatchOperationAssays: Assay[]): Observable<Assay[]> {
    if (selectedBatchOperationAssays.length) {
      const batchedAssays: any[] = this.getBatchedAssays(selectedBatchOperationAssays);

      return this.handleBatchAssayModification('put', accession._links.batchNoResult, {
        body: { batchItems: batchedAssays },
      });
    } else {
      return throwError('No selected batch operation assays');
    }
  }

  batchRepeat(accession: ChemistryAccession, selectedBatchOperationAssays: Assay[]): Observable<Assay[]> {
    if (selectedBatchOperationAssays.length) {
      const acceptableAssays: any[] = this.getBatchedAssays(selectedBatchOperationAssays);

      return this.handleBatchAssayModification('put', accession._links.batchRepeat, {
        body: { batchItems: acceptableAssays },
      });
    } else {
      return throwError('No selected batch operation assays');
    }
  }

  batchComment(
    accession: ChemistryAccession,
    comment: Comment,
    selectedBatchOperationAssays: Assay[]
  ): Observable<Assay[]> {
    if (selectedBatchOperationAssays.length) {
      const batchedAssays: any[] = this.getBatchedAssays(selectedBatchOperationAssays);

      return this.handleBatchAssayModification('put', accession._links.batchComment, {
        body: {
          commentId: comment.id,
          batchItems: batchedAssays,
        },
      });
    } else {
      return throwError('No selected batch operation assays');
    }
  }

  setNoResult(assay: Assay): Observable<Assay[]> {
    return this.handleIndividualAssayModification('put', assay._links.markAsNoResult);
  }

  repeat(assay: Assay): Observable<Assay[]> {
    if (!this.pending) {
      return this.handleIndividualAssayModification('put', assay._links.requestRepeat);
    } else {
      return EMPTY;
    }
  }

  addComment(assay: Assay, comment: Comment): Observable<Assay[]> {
    return this.handleIndividualAssayModification('post', assay._links.addComment, {
      body: { commentId: comment.id },
      observe: 'body',
    });
  }

  removeComment(comment: Comment): Observable<void> {
    this.pending = true;

    return this.http.delete(comment._links.removeComment.href, { observe: 'body' }).pipe(
      tap(() => {
        this.pending = false;
      }),
      map(() => {})
    );
  }

  getBatchedAssays(selectedBatchOperationAssays: Assay[]) {
    const batchedAssays: any = [];

    selectedBatchOperationAssays.forEach((assay) => {
      if (assay && assay.batchItem) {
        batchedAssays.push(assay.batchItem);
      }
    });

    return batchedAssays;
  }
}
