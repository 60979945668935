import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';

@Injectable()
export class AccessionHeaderValueService {
  private value = new Subject<Accession>();
  value$ = this.value.asObservable();

  setValue(accession: Accession) {
    this.value.next(accession);
  }
}
