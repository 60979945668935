import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Lab, LabsService, Link, User, WorkQueue, Workspace, WorkspaceAccession } from '@lims-common-ux/lux';
import { Assay } from './shared/interfaces/assay.interface';
import { DEFAULT_TITLE } from './app.defaults';
import { ApplicationInitService } from './application-init.service';
import { ResultsDataResource } from '@lims-common-ux/lux/lib/data-resources/results-data-resource.interface';

export interface ChemistryAccession extends WorkspaceAccession {
  assays: Assay[];
  _links: {
    assays: Link;
    batchAccept: Link;
    batchComment: Link;
    batchNoResult: Link;
    batchRepeat: Link;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  workspaces: Workspace[];
  workQueues: WorkQueue[];
  private isInitialized = false;
  private _loading = true;
  private _helpModalClosed: Subject<void> = new Subject();
  helpModalClosed = this._helpModalClosed.asObservable();
  private resultsResource: ResultsDataResource;

  constructor(
    @Inject('Document') private document: Document,
    private initService: ApplicationInitService,
    private labsService: LabsService
  ) {}

  configureSharedLinks(resource: ResultsDataResource) {
    this.resultsResource = resource;
  }

  triggerHelpModalClosed() {
    this._helpModalClosed.next();
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(isLoading: boolean) {
    if (!this.isInitialized && !this._loading) {
      this.isInitialized = true;
    }

    if (isLoading) {
      this.document.getElementById('app-loader').style.display = 'block';

      if (this.isInitialized) {
        this.document.getElementById('app-loader').classList.add('waiting-on-interaction');
      }
    } else {
      this.document.getElementById('app-loader').style.display = 'none';
    }

    this._loading = isLoading;
  }

  get labs(): Lab[] {
    return this.initService.staticAppData.labs;
  }

  get env(): string {
    return this.initService.staticAppData.environment;
  }

  get defaultPageTitle(): string {
    let title = `${DEFAULT_TITLE}`;

    if (this.initService.staticAppData.environment === 'uat' || this.initService.staticAppData.environment === 'exp') {
      title += ` (${this.initService.staticAppData.environment})`;
    }

    return title;
  }

  set lab(lab: Lab) {
    this.labsService.currentLab = lab;
  }

  get lab() {
    return this.labsService.currentLab;
  }

  get user(): User {
    return this.initService.staticAppData.currentUser;
  }

  get commentsDataSource(): Link {
    return this.verifyAndGetResultLinks('comments');
  }

  get accessionHeaderInfoLink(): Link {
    return this.verifyAndGetResultLinks('accession');
  }

  get accessionSearchLink(): Link {
    return this.verifyAndGetResultLinks('accessionSearch');
  }

  get addLabNoteLink(): Link {
    return this.verifyAndGetResultLinks('addLabNote');
  }

  get advancedAccessionSearchLink(): Link {
    return this.verifyAndGetResultLinks('accessionAdvancedSearch');
  }

  get getLabNotesLink(): Link {
    return this.verifyAndGetResultLinks('getLabNotes');
  }

  private verifyAndGetResultLinks(linkName: string): Link {
    const link = this.resultsResource?._links[linkName];
    if (link == null) {
      throw new Error(
        'Result links not set when looking for link ' +
          linkName +
          '. Please use `configureSharedLinks` to set this data appropriately.'
      );
    } else {
      return link;
    }
  }
}
