import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Assay, AssayStatus, ResultRequired } from '../../../shared/interfaces/assay.interface';
import { AssaysService } from '../assays.service';
import { ResultInputComponent } from './result-input/result-input.component';
import { Lab } from '@lims-common-ux/lux/lib/labs/lab.interface';

@Component({
  selector: 'app-assay-card',
  templateUrl: './assay-card.component.html',
  styleUrls: ['./assay-card.component.scss'],
})
export class AssayCardComponent implements OnInit, OnChanges {
  @ViewChild('displayValueLink', { static: false }) displayValueLink: ElementRef;
  @ViewChild('runValue', { static: false }) runValue: ResultInputComponent;
  @ViewChild('cardBody', { static: false }) body: ElementRef;

  @Input()
  assay: Assay;
  @Input()
  firstCard: boolean;
  @Input()
  lastCard: boolean;
  @Input()
  selectedAssay: Assay;
  @Input()
  isBatched = false;
  @Input()
  preventResultInput = false;
  @Input()
  lab: Lab;

  @Output()
  selectAssay: EventEmitter<Assay> = new EventEmitter();
  @Output()
  assayUpdated: EventEmitter<Assay[]> = new EventEmitter();
  @Output()
  batch: EventEmitter<boolean> = new EventEmitter();
  @Output()
  focusAssayDetails: EventEmitter<void> = new EventEmitter();

  isStacked: boolean;
  isSelected: boolean;
  isHovered: boolean;
  hasPreviousInvalid: boolean;
  editable = false;

  presentationClass: string;

  constructor(public assaysService: AssaysService) {}

  ngOnInit() {
    if (
      // only focus card if it matches the currently selectedAssay
      this.assay?.testCode === this.selectedAssay?.testCode &&
      // if there is a current result, or the assay is being repeated, don't default to edit mode
      ((this.assay && this.assay.currentResult) || this.assay.status === AssayStatus.REPEAT_REQUESTED)
    ) {
      this.focus();
    } else if (this.selectedAssay && this.assay.testCode === this.selectedAssay.testCode) {
      this.setEditable(true);
    }

    this.getCardWrapperPresentation();
  }

  getCardWrapperPresentation() {
    const classes = [];

    if (this.assay) {
      if (
        !this.isSelected &&
        this.assay.status === AssayStatus.OPENED &&
        this.lab.id !== this.assay.expectedPerformingLab &&
        (!this.assay?.currentResult || this.assay?.currentResult.value === null)
      ) {
        classes.push('performed-at-different-lab');
      }

      if (this.isStacked) {
        classes.push('stacked');
      }

      if (this.assay.status === AssayStatus.CANCELED) {
        classes.push('canceled');
      }

      if (this.assay.currentResult?.noResult) {
        classes.push('no-result');
      }

      if (this.assay.status === AssayStatus.TECHNICIAN_REVIEW || this.assay.currentResult?.technicallyInvalid) {
        classes.push('alert');
      }

      if (this.assay.status === AssayStatus.OPENED && this.assay.resultRequired === ResultRequired.NON_ESSENTIAL) {
        classes.push('non_essential');
      }

      if (this.hasPreviousInvalid) {
        classes.push('has-previous-invalid');
      }

      if (this.isSelected) {
        classes.push('selected');
      }

      classes.push(this.assaysService.getAssayStatusPresentation(this.assay));
    }

    this.presentationClass = classes.join(' ');
  }

  setCardPresentation() {
    setTimeout(() => {
      if (this.assay) {
        this.getCardWrapperPresentation();
      }
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isSelected = this.selectedAssay && this.assay.testCode === this.selectedAssay.testCode;
    this.isStacked = this.assay.results && this.assay.results.length > 1;
    if (this.isStacked) {
      this.hasPreviousInvalid =
        this.assay.results && this.assay.results.filter((result) => result.technicallyInvalid).length > 0;
    }
    this.setCardPresentation();
  }

  setEditable(editable: boolean) {
    if (
      (!this.runValue || (this.runValue && !this.runValue.runValueUpdated)) &&
      !this.preventResultInput &&
      this.assay._links?.addResult
    ) {
      this.editable = editable;
      if (this.editable) {
        this.focusRunValue();
      }
    }
  }

  // Apply a background color and outline to an active card
  highlightCard() {
    if (!this.isSelected) {
      this.selectAssay.emit(this.assay);
      // Allow users to manually enter results when none exist on card entry
      // when in repeat, force the user to verify they want to edit by double-clicking
      if (!this.assay.currentResult && this.assay.status !== AssayStatus.REPEAT_REQUESTED) {
        this.setEditable(true);
      } else {
        this.displayValueLink.nativeElement.focus();
      }
    }
  }

  handleClick($event) {
    if ($event.type === 'click') {
      if (!this.isSelected) {
        this.highlightCard();

        this.focus();
      } else {
        if (this.displayValueLink) {
          this.focus();
        } else {
          this.focusRunValue();
        }
      }
    }
  }

  handleDoubleClick($event) {
    if ($event.type === 'dblclick') {
      this.setEditable(true);
    }
  }

  handleEnterKeydown($event) {
    if ($event.code === 'Enter') {
      this.focusAssayDetails.emit();
    }
  }

  handleEscapeKeydown($event) {
    if ($event.code === 'Escape') {
      if (this.editable) {
        this.focus();
        this.editable = false;
      } else if (!this.isBatched) {
        this.selectAssay.emit(null);
      }
    }
  }

  // Focus the child anchor of the active card
  focus() {
    setTimeout(() => {
      if ((!this.editable || this.preventResultInput) && this.displayValueLink) {
        this.displayValueLink.nativeElement.focus();
        this.setCardPresentation();
      }
    }, 0);
  }

  focusRunValue() {
    setTimeout(() => {
      if (!this.preventResultInput) {
        this.runValue.focus();
        this.setCardPresentation();
      }
    }, 0);
  }

  blurRunResultInput() {
    this.editable = false;
  }

  handleSpace() {
    this.batchAssay();
  }

  batchAssay() {
    if (this.assay.batchItem) {
      this.batch.emit(this.isBatched);
      this.focus();
    }
  }

  // Special navigation handling only for first and last card in list
  handleKeydown($event) {
    if ($event.code === 'Enter') {
      if ($event.shiftKey) {
        this.setEditable(true);
      }
    } else {
      if (this.firstCard) {
        switch ($event.code) {
          case 'Tab': {
            if ($event.shiftKey) {
              this.selectAssay.emit(null);
            }
            break;
          }
          case 'ArrowUp': {
            this.selectAssay.emit(null);
            break;
          }
          default:
            return true;
        }
      } else if (this.lastCard) {
        switch ($event.code) {
          case 'Tab':
          case 'ArrowDown': {
            this.selectAssay.emit(null);
            break;
          }
          default:
            return true;
        }
      }
    }
  }

  toggleIsHovered() {
    this.isHovered = !this.isHovered;
  }
}
