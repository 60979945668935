import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, shareReplay, switchMap, Observable, of } from 'rxjs';
import { Link, WorkQueueItem, Workspace } from '@lims-common-ux/lux';
import { AppStateService } from '../app-state.service';
import { AccessionsService } from './accession/accessions.service';
import { WorkspaceAccessionService } from './workspace-accession.service';
import { Accession } from '../shared/interfaces/accession.interface';
import { Router } from '@angular/router';

interface QueueNext {
  accessionId: string;
  _links: {
    next: Link;
    self: Link;
  };
}

interface QueueAccessionInfo {
  next: Link;
  accessionId: string;
  accession: Accession;
}

@Injectable({
  providedIn: 'root',
})
export class WorkspaceQueueService {
  constructor(
    public appStateService: AppStateService,
    private accessionService: AccessionsService,
    private workspaceService: WorkspaceAccessionService,
    private http: HttpClient,
    private router: Router
  ) {}

  advanceQueue(workspaceQueueUrl: Link, currentWorkspace: Workspace): Observable<QueueAccessionInfo> {
    return this.http.get<QueueNext>(workspaceQueueUrl.href, { observe: 'response' }).pipe(
      switchMap((response) => {
        if (response.status === 200) {
          const workspaceQueue = response.body;
          return of({
            next: workspaceQueue._links.next,
            accessionId: workspaceQueue.accessionId,
          } as QueueAccessionInfo);
        } else {
          this.navigateToEmptyQueue(this.appStateService.lab.id, currentWorkspace.id);
          return of(null);
        }
      }),
      filter((queue) => queue != null),
      switchMap((queueResponse: QueueAccessionInfo) => {
        return this.accessionService
          .loadAccession(this.appStateService.accessionHeaderInfoLink, queueResponse.accessionId)
          .pipe(
            map((accession) => {
              queueResponse.accession = accession;
              return queueResponse;
            })
          );
      })
    );
  }

  getFirstQueueItem(nextLink: Link): Observable<WorkQueueItem> {
    return this.http.get<WorkQueueItem>(nextLink.href).pipe(shareReplay());
  }

  navigateToEmptyQueue(labId, workspaceId) {
    this.router.navigate(['lims-results', labId, 'workspaces', workspaceId, 'empty-queue']);
  }
}
