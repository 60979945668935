<div luxLayout="row" luxFill id="context-wrapper">
  <div luxLayout="column">
    <div luxLayout="row" luxFill>
      <div id="assay-details-wrapper" class="area-wrapper" luxLayout="column" luxFlex="385px">
        <app-assay-details
          luxFill
          [lab]="appStateService.lab"
          [accession]="accession"
          [headerAccession]="headerAccession"
          [commentsDataSource]="appStateService.commentsDataSource.href"
          [workspace]="workspace"
          [selectedAssay]="currentAssay"
          [released]="currentAssay?.status === 'RELEASED'"
          (noResultAssay)="handleNoResult()"
          (acceptAssay)="handleAcceptResult($event)"
          (repeatAssay)="handleRepeat()"
          (assayUpdated)="handleAssayUpdated([$event])"
          (escapeResultEntry)="handleResultEntryEscape()"
          (getNextQueueAccession)="nextInQueue()"
          (acceptAllResults)="triggerAcceptAllResults()"
          [batchedAssays]="selectedBatchOperationAssays"
          (deleteBatchedItem)="handleDeleteBatchedItem($event)"
          (removeAllBatchedItems)="handleRemoveAllBatchedItems()"
          (batchNoResult)="handleBatchNoResult()"
          (batchRepeat)="handleBatchRepeat()"
          (batchComment)="handleBatchComment($event)"
          [showNextLink]="nextInQueueLink !== null"
          #assayDetails>
        </app-assay-details>
      </div>
      <div luxLayout="column" id="assay-cards-wrapper">
        @if (accession?.accessionId) {
          <div luxLayout="row" luxLayoutAlign="right" style="padding: 13px 13px 0 0">
            <lux-lab-notes
              #labNotes
              *luxIfFeature="'LAB_NOTES'"
              dragBoundary="#context-wrapper"
              [getLabNotesLink]="appStateService.getLabNotesLink"
              [addLabNoteLink]="appStateService.addLabNoteLink"
              [accessionId]="accession.accessionId"
              [lab]="lab"
              [context]="'CHEMISTRY'"
              [labNotesPosition]="{ x: -370, y: 38 }"></lux-lab-notes>
          </div>
        }
        <app-assay-cards
          [lab]="appStateService.lab"
          [accession]="accession"
          [workspace]="workspace"
          [selectedAssay]="currentAssay"
          [batchedAssays]="selectedBatchOperationAssays"
          (selectAssay)="handleAssayCardSelection($event)"
          (batchTransmission)="handleBatchSelection($event)"
          (assayUpdated)="handleAssayUpdated($event)"
          (focusAssayDetails)="focusAssayDetails()"
          class="area-wrapper"
          #assayCards>
        </app-assay-cards>
      </div>
    </div>
    <div luxFlex luxLayout="row">
      <button
        data-cy="workspace-queue-next"
        title="{{ 'LABELS.SKIP' | translate }}"
        *ngIf="nextInQueueLink"
        (click)="nextInQueue()"
        [disabled]="!!(labNotes?.hasUnsavedLabNotes$ | async)"
        class="spot-button next-link spot-button--secondary spot-button--icon-only spot-button--with-icon">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>{{ 'LABELS.SKIP' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#back"></use>
        </svg>
      </button>
    </div>
  </div>
</div>
