import { Injectable } from '@angular/core';

import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  pageResourceUrl = '/index';

  constructor(public appStateService: AppStateService) {}
}
