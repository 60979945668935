<div luxLayout="row">
  <lux-header
    #header
    [env]="env"
    [accession]="accessionHeader | async"
    [availableLabs]="labs"
    [currentLab]="currentLab"
    [context]="'CHEMISTRY'"
    [configuration]="{
      accessionSearch: accessionSearchLink,
      accessionLinks: true,
      accessionAdvancedSearch: advancedAcessionSearchLink,
      attachments: (accessionHeader | async)?._links.attachments,
      localeSelector: true
    }"
    [workspaceId]="currentWorkspace?.id"
    [user]="user"
    (updateLab)="handleUpdateLab($event)"></lux-header>
</div>

<div id="lab-page" data-cy="app-page" luxLayout="row" *ngIf="currentLab">
  <lux-flyout
    luxFlex="40px"
    #flyout
    [navigationItems]="navItems"
    [currentNavItem]="currentWorkspace"
    (flyoutItemSelected)="changeWorkspace($event)"
    (flyoutButtonSelected)="startQueue($event)"
    (helpModalOpen)="helpModalOpen()">
  </lux-flyout>

  <div luxFlex>
    <router-outlet></router-outlet>
  </div>
</div>

<app-help-modal #helpModalContainer></app-help-modal>
